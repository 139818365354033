<template>
  <div :class="borderClass" class="relative py-3 flex items-center">
    <div @click="$refs.inputRef.focus()" class="w-full">
      <div class="mb-2">
        <label :for="inputId" class="block text-[#474747]">{{ label }}</label>
      </div>
      <!-- <div v-if="label || $attrs.placeholder" style="transition: top, font-size, 0.1s ease-in-out"
        class="absolute z-10 pointer-events-none bg-white" :class="`${labelStyle}`">
        <div class="w-full h-full relative bg-inherit">
          {{ label ? label : $attrs.placeholder }}
        </div>
      </div> -->
      <input :id="inputId" ref="inputRef" :class="[
        error
          ? 'focus:border-error border-error'
          : '',
        emp ? 'py-1' : 'py-3 p-16',
        isFocused ? 'border-primary' : 'border-none'
      ]" :style="emp ? 'height: 38px' : 'height: 48px'"
        class="bg-[#f8f8f8] w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border px-4 block appearance-none rounded-xl m-0"
        @focus="isFocused = true" @blur="isFocused = false" @input="handleInput" :inputmode="inputMode" v-bind="$attrs"
        :placeholder="placeholder" />
      <div v-if="error" class="text-xs font-medium text-error mb-3 -mt-2 text-left">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, useSlots, ref, watchEffect } from "vue";
import Imask from "imask";

const inputRef = ref();
const maskRef = ref();

const isFocused = ref(false);

const $slots = useSlots();

const $emit = defineEmits(["update:modelValue"]);
const inputControlledByMask = ref(false);
const __internalValue = ref("");

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  error: {
    type: [String, Boolean],
    default: null,
  },
  emp: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  inputId: {
    type: String
  },
  placeholder: {
    type: String,
    default: ''
  }
});

const currencyPattern = {
  mask: [
    {
      mask: "",
    },
    {
      mask: "₦num",
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          thousandsSeparator: ",",
          radix: ".",
          mapToRadix: [","],
        },
      },
    },
  ],
};

const percentagePattern = {
  mask: [
    {
      mask: "",
    },
    {
      mask: "num%",
      lazy: false,
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          min: 0,
          max: 100,
          radix: ".",
          mapToRadix: [","],
        },
      },
    },
  ],
};

watchEffect(
  (onCleanUp) => {
    const el = inputRef.value;
    let maskPattern;

    if (props.type === "currency") {
      maskPattern = currencyPattern;
    } else if (props.type === "percentage") {
      maskPattern = percentagePattern;
    }

    if (maskPattern) {
      inputControlledByMask.value = true;
      let mask = Imask(el, maskPattern);

      maskRef.value = mask;

      const handleChange = () => {
        let cleanedValue = toNum(mask.unmaskedValue);

        __internalValue.value = cleanedValue;
        $emit("update:modelValue", cleanedValue);
      };

      // update change immediately
      handleChange();

      mask.on("accept", handleChange);

      onCleanUp(() => {
        mask.off("accept");
        mask.destroy();
      });
    }
  },
  {
    flush: "post",
  }
);

watchEffect(() => {
  if (
    inputControlledByMask.value === true &&
    __internalValue.value !== props.modelValue
  ) {
    let mask = maskRef.value;
    mask.typedValue = String(props.modelValue);
  }
});

function toNum(value) {
  if (value !== "") {
    value = Number(value);
  }
  return value;
}

const handleInput = (e) => {
  if (inputControlledByMask.value !== true) {
    $emit("update:modelValue", e.target.value);
  }
};

const inputMode = computed(() => {
  if (["percentage", "currency"].includes(props.type)) {
    return "decimal"
  }
  return
});

const borderClass = computed(() => {
  let style = "";
  if (props.error) {
    style = "border-error";
  } else {
    if (isFocused.value) {
      style = "border-primary";
    } else {
      style = "border-dark-300";
    }
  }

  return style;
});

const labelStyle = computed(() => {
  let style = [];

  if (isFocused.value) {
    style = " floatingLabel text-sm ";
    style = props.error ? `${style} text-error` : `${style} text-primary`;
    if ($slots.append) {
      style = `${style} left-6`;
    }
  } else {
    if (props.modelValue.toString().trim() === "") {
      style = " first top-1/2 left-7 transform -translate-y-1/2 text-dark-300";
    } else {
      style = " other floatingLabel text-sm text-primary";
    }
    if ($slots.append) {
      style = `${style} left-6`;
    }
  }

  return style;
});
</script>

<style scoped>
.m-0 {
  margin: 0px !important;
}

/* input {
  @apply outline-none ;
} */
</style>
