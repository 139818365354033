<template>
  <section @mouseover="changeImgPosition = true" @mouseleave="changeImgPosition = false" class="benefits-page">
    <div class="w-full lg:w-[1100px] mx-auto px-4">
      <div class="w-full sm:w-[496px] flex flex-col justify-between">
        <h6 class="uppercase font-medium text-[#d4d4d4]">
          <span class="text-xs">️⚫</span> benefits
        </h6>

        <h3 class="text-white font-medium text-[25px] sm:text-4xl mt-8">
          Never Again <del class="text-[#d9ebcd] italic">Miss Payroll</del>
        </h3>
        <p class="text-[#e9e9e9] pt-6 text-[18px] lg:text-xl">
          ‌ Use credits to pay your staff on time, so you never miss a payroll date again.
           
        </p>
        <EaziBtn :is-link="true" to="/register" :with-icon="true" variant="sec" class="w-[218px] h-12 mt-8">
          Get Payroll Cover Now
        </EaziBtn>
      </div>
    </div>
    <div
      class="relative md:absolute w-full sm:w-[500px] right-0 sm:-right-[12rem] md:right-0 md:top-1/2 md:-translate-y-1/2">
      <div class="img-section">
        <img src="./assets/benefits/Frame2.png" alt="" class="img1" :class="{ 'img1-hover': changeImgPosition }" />
        <img src="./assets/benefits/frame1.png" alt="" class="img2" :class="{ 'img2-hover': changeImgPosition }" />
        <img src="./assets/benefits/Frame3.png" alt="" class="img3" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import EaziBtn from "./ui/EaziBtn.vue";

const changeImgPosition = ref(false);
</script>

<style lang="scss" scoped>
.benefits-page {
  @apply w-full h-full lg:h-[610px] flex gap-6 flex-wrap-reverse lg:block py-20 lg:py-[120px] relative;
  background: rgba(17, 69, 59, 1);
}

.img-section {
  @apply relative w-full h-[390px];
  transition: transform 0.2s ease;

  img {
    transition: transform 0.2s ease;
  }
}

.img1 {
  @apply absolute left-7 sm:left-[8rem] lg:left-[3rem] w-[256px] top-10 xs:top-0 object-contain;
  z-index: 100;
  transform: translateY(-10px);
}

.img2 {
  @apply h-[360px] absolute object-contain right-0 w-auto rounded-4xl translate-x-[160px] lg:translate-x-20;
  // transform: translateX(80px);
}

.img3 {
  @apply w-[300px] object-contain z-50 absolute bottom-0 left-1 lg:-left-[5rem];
}

.img1-hover {
  animation: slide-up 0.3s forwards;
}

.img2-hover {
  animation: slide-in 0.3s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(80px);
  }

  100% {
    transform: translateX(40px);
  }
}

@keyframes slide-up {
  0% {
    transform: translate(1px, -10px);
    // transform: translateX();
  }

  100% {
    transform: translate(-40px, 90px);
    // transform: translateX();
  }
}
</style>
