<template>
  <div class="circle-container">
    <Swiper :class="bgClass" :modules="[Autoplay]" :slides-per-view="1" :loop="true"
      :autoplay="{ delay: 2000, disableOnInteraction: false }" class="circleOne">
      <SwiperSlide v-for="item in orderedSlides" :key="item.id" class="h-full w-full flex items-center justify-center">
        <img :src="`${item.img}`" :alt="item.alt" :class="bgType === 'second' ? 'w-10 lg:w-16' : 'w-10 lg:w-[80px]'" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from "swiper/modules";

const props = defineProps({
  bgType: String
})

const bgClass = computed(() => {
  return props.bgType === 'first'
    ? 'bg-[#A0915E80] size-12 lg:size-24'
    : props.bgType === 'second'
      ? 'bg-[#D9EBCD80] size-12 lg:size-[78px]'
      : 'bg-[#FF6C694D] size-12 lg:size-24'
})

/*
:class="bgType === 'first'
    ? 'bg-[#A0915E80]' : bgType === 'second'
      ? 'bg-[#D9EBCD80]'
      : 'bg-[#FF6C694D]'"
*/

const orderedSlides = computed(() => {
  const slides = [
    { id: '1', alt: 'random human image', img: new URL('../assets/img1.png', import.meta.url) },
    { id: '2', alt: 'random human image', img: new URL('../assets/img2.png', import.meta.url) },
    { id: '3', alt: 'random human image', img: new URL('../assets/img3.png', import.meta.url) },
  ];

  // Change order based on bgType
  if (props.bgType === 'second') {
    return [slides[2], slides[0], slides[1]]; // Reorder: 3 → 1 → 2
  } else if (props.bgType === 'first') {
    return [slides[1], slides[2], slides[0]]; // Reorder: 2 → 3 → 1
  }

  return slides; // Default order
});
</script>

<style lang="scss" scoped>
.circle-container {
  @apply rounded-full relative;
  // z-index: 100;
}

.circleOne {
  @apply rounded-full flex items-center shadow-md justify-center;
  // z-index: inherit;
}
</style>