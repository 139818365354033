<template>
  <section class="expectation">
    <p class="uppercase text-[#888888] text-center">
      <span class="text-xs">️⚫️</span> Services
    </p>

    <div class="w-full xl:w-[1100px] mx-auto mt-[32px] flex flex-col gap-12 items-center">
      <h3 class="w-full text-[28px] md:text-[40px] text-center font-medium leading-9 md:leading-[48px] text-[#191919]">
        Clear Expectations, <br />
        Reliable Delivery
      </h3>

      <div class="w-full flex flex-wrap justify-center lg:grid lg:grid-cols-3 gap-8 justify-items-center">
        <div v-for="(item, index) in expectationLists" :key="item.id" class="card"
          :style="{ backgroundColor: item.cardBg, border: `1px solid ${item.cardBg}` }"
          :class="activeIndex === index || !isMobile ? 'h-auto' : 'h-16'">
          <div @click="toggleAccordion(index)"
            class="w-full flex absolute left-0 pr-3 top-0 justify-between items-center">
            <div class="flex items-center gap-4">
              <div class="iconBg" :class="activeIndex === index || !isMobile ? 'mini-card' : 'rounded-l-3xl'
                " :style="{ backgroundColor: item.iconBg }">
                <img :src="item.icon" alt="" class="w-[35px]" />
              </div>
              <div v-html="item.title" class="font-medium block sm:hidden leading-6 text-[#474747]" />
            </div>

            <div role="button" class="size-9 bg-white rounded-lg flex sm:hidden items-center justify-center">
              <PageIcon v-if="activeIndex === index" icon="minus" />
              <PageIcon v-else icon="plus" />
            </div>
          </div>

          <div v-html="item.title" class="hidden sm:block mt-[85px] text-xl font-medium text-[#474747]" />
          <div v-if="activeIndex === index || !isMobile" class="mt-[90px] sm:mt-0"
            :class="activeIndex === index || !isMobile ? 'h-auto' : ''">
            <p class="text-14 font-medium text-[#474747]">️️💠 ‌Timeline</p>
            <p class="text-14 pt-1">{{ item.timeline }}</p>
            <div class="mt-3">
              <p class="text-14 font-medium text-[#474747]">️️💠 ‌What to Expect</p>
              <p class="text-14 pt-1">{{ item.whatToExpect }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex justify-between">
        <h3 class="text-14 md:text-[18px] font-medium text-[#474747]">
          Explore Our Standout <br />
          Solutions
        </h3>
        <EaziBtn :is-link="true" to="/register?page=1" variant="tertiary" class="w-[163px] h-12">
          Get Started Now
        </EaziBtn>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useMediaQuery } from "@vueuse/core";
import { ref } from "vue";
import EaziBtn from "./ui/EaziBtn.vue";
import PageIcon from "./ui/PageIcon.vue";

const expectationLists = ref([
  {
    id: "1",
    title: "<h5>PAYE Registration <br /> (Employer)</h5>",
    timeline: "24 hours after successful application",
    whatToExpect: "‌Corporate Taxpayer ID - State",
    icon: new URL("./assets/expectatn/image1.png", import.meta.url),
    iconBg: "#fb8417",
    cardBg: "#fff3e8",
  },
  {
    id: "2",
    title: "<h5>PAYE Registration <br/> (Employee)</h5>",
    timeline: "24 hours after successful application",
    whatToExpect: "‌Taxpayer ID - State of Residence",
    icon: new URL("./assets/expectatn/image2.png", import.meta.url),
    iconBg: "#2f80ed",
    cardBg: "#ebf3fe",
  },
  {
    id: "3",
    title: "<h5>Pension Registration <br/> (Employer)</h5>",
    timeline: "‌2-3 weeks after successful application",
    whatToExpect: "‌Employer Pension Code",
    icon: new URL("./assets/expectatn/image3.png", import.meta.url),
    iconBg: "#b4a572",
    cardBg: "#b4a5721a",
  },
  {
    id: "4",
    title: "<h5>‌Pension Registration <br /> (Employee)</h5>",
    timeline: "‌2-3 weeks after successful application",
    whatToExpect: "‌Employee RSA Pin",
    icon: new URL("./assets/expectatn/image4.png", import.meta.url),
    iconBg: "#4789a8",
    cardBg: "#4789a81a",
  },
  {
    id: "5",
    title: "<h5>‌Annual Filing of PAYE Tax</h5>",
    timeline: "‌On or before January 31st every year, across the 36 States + FCT",
    whatToExpect: "‌Evidence of filing/TCC",
    icon: new URL("./assets/expectatn/image5.png", import.meta.url),
    iconBg: "#3aad5f",
    cardBg: "#3aad5f1a",
  },
  {
    id: "6",
    title: "<h5>‌Pension <br /> Certificate </h5>",
    timeline: "‌4 weeks",
    whatToExpect: "‌Pension Certificate",
    icon: new URL("./assets/expectatn/image6.png", import.meta.url),
    iconBg: "#fec227",
    cardBg: "#fec2271a",
  },
]);

const isMobile = useMediaQuery("(max-width: 425px)");

const activeIndex = ref(0);

const toggleAccordion = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index;
};
</script>

<style lang="scss" scoped>
.expectation {
  @apply w-full h-full lg:h-[1068px] px-4 xl:px-0 py-16;
  background-color: rgba(255, 255, 255, 1);

  .card {
    @apply w-full xs:w-[325px] rounded-2xl sm:rounded-[32px] relative flex flex-col px-6 pb-6 gap-4;
    // border: 1px solid rgba(255, 243, 232, 1);
    box-shadow: 0px 20px 56px 0px rgba(170, 170, 170, 0.08);
    transition: 0.3s ease-out;
  }

  .iconBg {
    @apply size-16 sm:size-[73px] flex items-center justify-center;
  }
}

.accordion-header {
  @apply w-full flex items-center justify-between bg-white p-4;
  box-shadow: 6px 6px 12px rgba(170, 170, 170, 0.11);
  color: rgba(71, 71, 71, 1);

  .ctrl {
    @apply size-10 bg-[#f3f3f3] flex items-center justify-center rounded-lg;
  }
}

.mini-card {
  @apply rounded-tl-2xl sm:rounded-tl-4xl rounded-tr-2xl sm:rounded-tr-3xl rounded-bl-2xl sm:rounded-bl-2xl rounded-br-2xl sm:rounded-br-3xl;
}
</style>
