<template>
  <div class="accordion">
    <div class="flex items-center justify-between" @click="toggleAccordion">
      <slot name="title" :is-open="isActive"></slot>
    </div>

    <transition name="accordion">
      <div v-if="isActive" class="accordion-content">
        <slot name="content" :is-open="isActive"></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';



const props = defineProps({
  index: Number,
  isActive: Boolean,
})

const emit = defineEmits(["toggle"]);

const toggleAccordion = () => {
  emit("toggle");
};

const content = ref(null);

// const contentStyle = computed(() => ({
//   maxHeight: props.isActive ? `${content.value?.scrollHeight}px` : "0px",
//   opacity: props.isActive ? "1" : "0",
//   transform: props.isActive ? "translateY(0)" : "translateY(-10px)",
//   transition: "max-height 0.3s ease-out, opacity 0.2s ease, transform 0.3s ease",
//   // position: props.isActive ? 'relative' : 'absolute left-0'
// }));
</script>

<style lang="scss" scoped>
.accordion {
  @apply w-auto transition-all;
  overflow: hidden;

}

// .accordion-content {
//   min-height: 0;
//   transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
// }


// .accordion-content-wrapper.open {
//   min-height: 100%;
// }

// .accordion-enter-active,
// .accordion-leave-active {
//   transition: max-height 0.3s ease-out, opacity 0.3s;
// }

// .accordion-enter,
// .accordion-leave-to {
//   max-height: 0;
//   opacity: 0;
// }</style>