<template>
  <footer class="site-footer">
    <div class="footer" :class="$route.path !== '/' ? 'w-full xl:w-[1170px]' : 'w-full xl:w-[1100px]'">
      <div class="first-col">
        <div
          class="flex flex-col gap-10 order-2 items-center lg:items-start justify-between lg:justify-start lg:order-1">
          <router-link to="/" class="w-full flex justify-center lg:justify-start">
            <img src="./assets/eazi-logo.png" alt="logo" class="w-[122px] block" />
          </router-link>
          <div class="text-14 text-[#515251] text-center lg:text-start">
            <p>Copyright © 2025 Eazipay.</p>
            <p class="pt-2">All rights reserved</p>
          </div>
          <div class="flex gap-3">
            <a v-for="(link, index) in socialLinks" :key="`social-link-${index}`" :href="link.href" :title="link.title"
              target="_blank" rel="noopener noreferrer" class="social-link block">
              <component :is="link.icon" />
            </a>
          </div>
        </div>

        <div class="order-1 block sm:hidden lg:block lg:order-2 text-center lg:text-start">
          <p class="text-14 font-semibold text-[#292a29]">
            Download the Eazipay Mobile App
          </p>
          <div class="flex gap-4 mt-4">
            <a target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/eazipay-business/id6446281426" class="get-app">
              <div class="icon-bg">
                <span class="pi pi-apple" style="font-size: 20px" />
              </div>
              <div>
                <p>Get on</p>
                <h6>iPhone</h6>
              </div>
            </a>
            <a target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.myeazipay.business" class="get-app">
              <div class="icon-bg">
                <span class="pi pi-android" style="font-size: 20px" />
              </div>
              <div>
                <p>Get on</p>
                <h6>Android</h6>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="flex-1 w-full">
        <div class="h-full flex flex-col justify-between">
          <div class="flex justify-between flex-wrap-reverse lg:flex-nowrap gap-10 lg:gap-4">
            <!-- <div class="flex flex-1 justify-between"> -->
            <div class="w-auto lg:w-1/4 order-2 lg:order-none">
              <h3 class="col-heading">Products</h3>
              <ul class="col">
                <li>
                  <router-link to="/">Businesses</router-link>
                </li>
                <li>
                  <a href="http://hrstores.eazipay.ng/">HR Stores</a>
                  <!-- <router-link to="/hr-stores">HR Stores</router-link> -->
                </li>
                <li>
                  <router-link to="/paye">Annual PAYE Filing</router-link>
                </li>
                <!-- <li>
                  <router-link to="/assess-employee">Assessment</router-link>
                </li> -->
              </ul>
            </div>
            <div class="w-full flex flex-col items-center lg:items-start order-1 lg:order-none lg:w-1/4 mb-10 lg:mb-0">
              <h3 class="col-heading">Legal</h3>
              <ul class="col items-center lg:items-start">
                <li>
                  <router-link to="/privacy-policy">Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/terms-of-use">Terms of Service</router-link>
                </li>
              </ul>
            </div>
            <div class="w-auto lg:w-1/4 order-3 lg:order-none">
              <h3 class="col-heading">Resources</h3>
              <ul class="col">
                <li>
                  <router-link to="/faqs">FAQs</router-link>
                </li>
                <li>
                  <a href="https://blog.eazipay.ng/">Blog</a>
                </li>
                <li>
                  <a href="https://eazipay.tawk.help">Help Page</a>
                </li>
                <!-- <li>
                  <router-link to="#">Career</router-link>
                </li>
                <li>
                  <router-link to="#testimonials">Customer Stories</router-link>
                </li>
                <li>
                  <router-link to="/business">Request Demo</router-link>
                </li> -->
              </ul>
            </div>
            <!-- </div> -->

            <div class="contact">
              <div class="text-center hidden sm:block lg:hidden mb-6">
                <p class="text-14 font-semibold text-[#292a29]">
                  Download the Eazipay Mobile App
                </p>
                <div class="flex gap-4 mt-4">
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/eazipay-business/id6446281426" class="get-app">
                    <div class="icon-bg">
                      <span class="pi pi-apple" style="font-size: 20px" />
                    </div>
                    <div>
                      <p>Get on</p>
                      <h6>iPhone</h6>
                    </div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.myeazipay.business" class="get-app">
                    <div class="icon-bg">
                      <span class="pi pi-android" style="font-size: 20px" />
                    </div>
                    <div>
                      <p>Get on</p>
                      <h6>Android</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div class="text-center lg:text-start">
                <h3 class="col-heading">Contact us</h3>
                <ul class="col">
                  <li>
                    <a href="mailto:hi@myeazipay.net"> hi@myeazipay.net </a>
                  </li>
                  <li>
                    <a href="tel:+2347000332947">07000332947 (070003EAZIPAY) </a>
                  </li>
                </ul>
              </div>
              <div class="pt-3">
                <FooterContactForm />
              </div>
            </div>
          </div>
          <div class="hidden lg:flex gap-4 items-center">
            <img src="./assets/npdr.png" alt="" class="w-20" />
            <p class="text-center">
              We are <span class="text-[#11453b] font-bold">NDPR</span> <br />
              Compliant
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import TwitterIcon from "@/www/components/social-icons/Twitter.vue";
import LinkedinIcon from "@/www/components/social-icons/Linkedin.vue";
import InstagramIcon from "@/www/components/social-icons/Instagram.vue";
import FacebookIcon from "@/www/components/social-icons/Facebook.vue";
import FooterContactForm from "@/www/components/FooterContactForm.vue";

const socialLinks = [
  {
    title: "Instagram",
    href: "https://www.instagram.com/myeazipay?igsh=Mmx5c2lsaHdxNjlm",
    icon: InstagramIcon,
  },
  {
    title: "Twitter",
    href: "https://www.twitter.com/myeazipay",
    icon: TwitterIcon,
  },
  {
    title: "Linkedin",
    href: "https://www.linkedin.com/company/myeazipay/",
    icon: LinkedinIcon,
  },
  {
    title: "Facebook",
    href: "https://web.facebook.com/myeazipay",
    icon: FacebookIcon,
  },
];
</script>

<style lang="scss" scoped>
.site-footer {
  @apply w-full py-16 px-4 lg:px-0;
  border-top: 1px solid #eaeaea;

  // .footer-cols {
  // }

  .col-heading {
    @apply font-bold mb-2 lg:mb-4 text-xl leading-6;
  }

  .footer-list {
    display: grid;
    gap: 12px;

    li {
      color: #7c7c7c;
      font-size: 16px;
      line-height: 24px;
    }

    a:hover {
      color: #ea4e4b;
    }
  }
}

.footer {
  @apply mx-auto flex flex-wrap-reverse lg:flex-nowrap gap-5 px-4 xl:px-0;
}

.first-col {
  @apply flex w-full items-center lg:items-start justify-center lg:flex-nowrap lg:w-[310px] flex-col gap-10;
}

.col {
  @apply flex flex-col gap-3;
  color: #7c7c7c;
}

.get-app {
  @apply w-[140px] sm:w-[215px] lg:w-[134px] h-14 rounded-[30px] flex items-center py-2 px-3 gap-3;
  background: rgba(243, 243, 243, 1);

  .icon-bg {
    @apply size-10 flex rounded-full items-center justify-center bg-white;
  }

  p {
    @apply text-[10px];
    color: rgba(81, 82, 81, 1);
  }

  h6 {
    @apply text-14 font-semibold;
    color: rgba(41, 42, 41, 1);
  }
}

.contact {
  @apply order-4 lg:order-none flex flex-col items-center lg:items-start gap-6 lg:gap-0 w-full lg:w-auto;
}
</style>
