<template>
  <section class="faq-container">
    <div class="w-full sm:w-[640px] mx-auto flex flex-col items-center gap-12">
      <h3 class="font-medium text-4xl text-[#191919]">
        Frequently Asked Questions
      </h3>

      <div class="w-full">
        <AccordionPanel v-for="(item, idx) in items" :key="item.id" :isActive="activeIndex === idx"
          @toggle="toggleAccordion(idx)" class="mb-4">
          <template #title="{ isOpen }">
            <header class="accordion-header"
              :class="isOpen ? 'rounded-t-2xl border-none' : 'rounded-2xl border-[#ffffff1a]'">
              <h5 class="font-medium">{{ item.title }}</h5>

              <span role="button" class="ctrl">
                <PageIcon v-if="isOpen" icon="minus" />
                <PageIcon v-else icon="plus" />
              </span>
            </header>
          </template>
          <template #content="{ isOpen }">
            <i class="w-full h-2 bg-[#f8f8f8] block" />
            <div class="accordion-content">
              <p>{{ item.content }}</p>
            </div>
          </template>
        </AccordionPanel>
      </div>

      <div>

        <EaziBtn :is-link="true" to="/faqs" :withIcon="true" variant="tertiary" class="w-[196px] h-12">
          <p> See all Questions</p>
        </EaziBtn>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import AccordionPanel from './ui/AccordionPanel.vue';
import PageIcon from './ui/PageIcon.vue';
import EaziBtn from "./ui/EaziBtn.vue";


const items = [
  {
    id: 1,
    title: 'What does Eazipay offer?',
    content: "Eazipay makes managing Payments, HR, and Compliance effortless. With Eazipay, you can process bulk salary payments, handle PAYE, pensions, and other compliance tasks with ease. Plus, you can hire and assess employees using top-tier evaluation tools—all in one place. It’s the go-to solution for businesses that want to work smarter, not harder."
  },
  {
    id: 2,
    title: 'How is Eazipay different from a regular bank?',
    content: "Unlike banks, Eazipay doesn’t just process payments—it automates payroll, tax, and compliance, eliminating errors and saving time. Payroll is completed within five minutes, whereas banks might take hours or even days. Plus, Eazipay includes features like payslips, hiring tools, employee assessments, loans, and salary advances at no extra cost. It’s an all-in-one platform for payroll, HR, and compliance, designed to make business operations faster and easier."
  },
  {
    id: 3,
    title: 'What are the pricing plans for using Eazipay?',
    content: "Pricing starts with a Free plan for a team with at least 10 staff. If your team has fewer than 10 staff, a ₦5,000 base fee applies."
  },
  {
    id: 4,
    title: 'Can I add users to my account?',
    content: "Yes, you may add your HR, Finance, and any other person, and assign roles and limit."
  },
  {
    id: 5,
    title: 'How secure is Eazipay?',
    content: "Security is a top priority. You may use two-factor authentication (2FA), biometric verification, and multiple security layers to keep your data and transactions safe. We follow strict privacy policies, ensuring your information is never shared without consent."
  },

]

const activeIndex = ref(0);
const toggleAccordion = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index; // Close previous, open new
};
</script>

<style lang="scss" scoped>
.faq-container {
  @apply w-full h-full md:h-[1016px] py-[120px] px-4 bg-[url('./assets/faq-bg.png')] bg-no-repeat bg-contain bg-[#f8f8f8];
}

.accordion-header {
  @apply w-full flex items-center justify-between bg-white p-4;
  box-shadow: 6px 6px 12px rgba(170, 170, 170, 0.11);
  color: rgba(71, 71, 71, 1);

  .ctrl {
    @apply size-10 bg-[#f3f3f3] flex items-center justify-center rounded-lg;
  }
}

.accordion-content {
  @apply bg-white p-6 rounded-b-2xl;
  box-shadow: 6px 6px 12px rgba(170, 170, 170, 0.11);
  color: rgba(71, 71, 71, 1);
}
</style>