<template>
  <nav class="w-full">
    <div class="w-full flex items-center justify-between">
      <RouterLink to="/">
        <img v-if="$route.path === '/'" src="../assets/logo.png" alt="logo" class="w-[122px]" />
        <Logo v-else />
      </RouterLink>
      <div class="hidden lg:flex">
        <ul class="nav-links items-center gap-6">
          <li>
            <MegaMenu :menuItems="ProductMenuItems">
              <template #default="slotProps">
                <a @click="slotProps.toggleMenu()" class="nav-link cursor-pointer flex items-center gap-1.5"
                  :class="$route.path === '/' ? 'text-white' : 'text-[#292a29]'">
                  <span>Products</span>
                  <span class="block mt-1">
                    <PageIcon icon="arr-down" />
                  </span>
                </a>
              </template>
            </MegaMenu>
          </li>
          <li>
            <router-link :class="$route.path === '/' ? 'text-white' : 'text-[#292a29]'" class="nav-link"
              to="/business?scroll=true">Set your payroll</router-link>
          </li>
        </ul>
      </div>

      <div class="hidden lg:flex items-center">
        <div class="auth-links">
          <RouterLink to="/login" class="bg-transparent"
            :class="$route.path === '/' ? 'text-white  hover:bg-primary' : 'login-btn'">
            Sign in
          </RouterLink>

          <RouterLink to="/register" class="hover:bg-primary link" :class="$route.path === '/' ? 'bg-white text-primary' : 'bg-primary text-white'
            ">
            Sign up
          </RouterLink>
        </div>
      </div>
      <div class="lg:hidden flex flex-1 justify-end">
        <button :class="$route.path !== '/' ? 'route-menu' : 'hamburger'" @click="openMobileNav">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>

    <div class="mobile-nav-overlay fixed left-0 top-0 right-0 bottom-0" :class="{ show: show === true }"
      @click.self="closeMobileNav"></div>
    <div class="mobile-nav-menu" :class="{ open: show === true }">
      <div class="px-4 pb-12">
        <div class="flex pt-14 md:py-6 items-center justify-between mb-10">
          <Logo />
          <div>
            <button class="mobile-menu-close-btn p-2 hover:opacity-60" @click="closeMobileNav">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div :style="{
          backgroundImage: ' url(' + background + ')',
        }" style="
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            scrollbar-width: none;
          " class="pt-4 pb-36 h-screen overflow-auto">
          <p class="font-medium text-sm text-grey mb-4">PRODUCTS</p>
          <div class="bg-white rounded-xl mb-7">
            <div v-for="(menu, i) in ProductMenuItems" :key="i" @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null" @click="
                $router.push(menu.routes);
              showMenu = false;
              " :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight">
              <div :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center">
                <img :src="hoveringItem === menu.title || activeLink == menu.routes
                    ? menu.iconActive
                    : menu.icon
                  " alt="" />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton @click="openMobile" color="primary w-full" shadow to="#">
            Download Eazipay mobile app
          </SiteButton>
          <hr class="my-5" />

          <!-- <p class="font-medium text-sm text-grey mb-4">EMPLOYEES</p>
          <div class="bg-white rounded-xl mb-7">
            <div v-for="(menu, i) in EmployeeMenuItems" :key="i" @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null" @click="
                $router.push(menu.routes);
              showMenu = false;
              " :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight">
              <div :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center">
                <img :src="hoveringItem === menu.title || activeLink == menu.routes
                    ? menu.iconActive
                    : menu.icon
                  " alt="" />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
                <p class="text-sm text-dark-600 line-clamp-2">
                  {{ menu.description }}
                </p>
              </div>
            </div>
          </div>
          <SiteButton color=" primary w-full" shadow to="#">
            Download WorknProsper mobile app
          </SiteButton>
          <hr class="my-8" /> -->
          <p class="font-medium text-sm text-grey mb-4">Developers</p>
          <div class="bg-white rounded-xl mb-7">
            <div v-for="(menu, i) in DeveloperMenuItems" :key="i" @mouseover="hoveringItem = menu.title"
              @mouseout="hoveringItem = null" @click="
                $router.push(menu.routes);
              showMenu = false;
              " :class="[activeLink == menu.routes ? 'bg-highlight' : '']"
              class="flex items-center cursor-pointer group w-fit gap-4 p-4 rounded-xl hover:bg-highlight">
              <div :class="[activeLink == menu.routes ? 'bg-white' : 'bg-lightGreyBg']"
                class="w-12 h-12 rounded-lg group-hover:bg-white flex-shrink-0 flex items-center justify-center">
                <img :src="hoveringItem === menu.title || activeLink == menu.routes
                    ? menu.iconActive
                    : menu.icon
                  " alt="" />
              </div>

              <div class="flex flex-col">
                <h3 class="text-base font-semibold text-dark-900">
                  {{ menu.title }}
                </h3>
              </div>
            </div>
          </div>

          <hr class="my-8" />

          <SiteButton color="primary w-full" shadow to="/login"> Sign in </SiteButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref } from "vue";
import MegaMenu from "@/www/components/MegaMenu.vue";
import background from "@/www/components/assets/mobileBackground.png";
import SiteButton from "@/www/components/SiteButton.vue";
import CloseIcon from "@/www/components/icons/Close.vue";
import PageIcon from "./PageIcon.vue";
import Logo from "@/www/components/Logo.vue";

const hoveringItem = ref(null);

const activeLink = ref(null);
const show = ref(false);
const isHovered = ref(false);
const closeMobileNav = () => (show.value = false);
const openMobileNav = () => (show.value = true);
const showDiv = () => {
  isHovered.value = true;
};

const hideDiv = () => {
  isHovered.value = false;
};

const ProductMenuItems = computed(() => {
  return [
    {
      title: "Business",
      description: `Add your employee the simple way you know and run their payroll.All in seconds!`,
      routes: "/business",
      routeName: null,
      icon: new URL("../../../components/assets/business.svg", import.meta.url),
      iconActive: new URL(
        "../../../components/assets/business-active.svg",
        import.meta.url
      ),
    },
    {
      title: "HR Store",
      description:
        "The all-inclusive platform to buy or rent company assets and employee perks, curated to ensure the happiness of your staff",
      routes: "http://hrstores.eazipay.ng/",
      routeName: null,
      icon: new URL("../../../components/assets/hr.svg", import.meta.url),
      iconActive: new URL("../../../components/assets/hr-active.svg", import.meta.url),
      external: true,
    },

    {
      title: "Annual PAYE Filing",
      description: `Beat the deadline and save N500,000 in tax penalties.`,
      routes: "/paye",
      routeName: null,
      icon: new URL("../../../components/assets/paye.svg", import.meta.url),
      iconActive: new URL("../../../components/assets/paye-active.svg", import.meta.url),
    },
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "/wnpLanding",
      routeName: null,
      icon: new URL("../../../components/assets/wnp.svg", import.meta.url),
      iconActive: new URL("../../../components/assets/wnpActive.svg", import.meta.url),
    },
  ];
});

// const EmployeeMenuItems = computed(() => {
//   return [
//     {
//       title: "WorknProsper",
//       description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
//       routes: "/wnpLanding",
//       routeName: null,
//       icon: new URL("../../../components/assets/wnp.svg", import.meta.url),
//       iconActive: new URL("../../../components/assets/wnpActive.svg", import.meta.url),
//     },
//   ];
// });
const DeveloperMenuItems = computed(() => {
  return [
    {
      title: "Overview",
      routes: "/developers",
      routeName: null,
      icon: new URL("../../../components/assets/overview.svg", import.meta.url),
      iconActive: new URL(
        "../../../components/assets/overviewActive.svg",
        import.meta.url
      ),
    },
    {
      title: "API Documentation",
      description: ``,
      routes: "/developers/documentation",
      routeName: null,
      icon: new URL("../../../components/assets/apiDocumentation.svg", import.meta.url),
      iconActive: new URL(
        "../../../components/assets/apiDocumentationActive.svg",
        import.meta.url
      ),
    },
    {
      title: "API Status",
      description: ``,
      routes: "/#",
      routeName: null,
      icon: new URL("../../../components/assets/apiDocumentation.svg", import.meta.url),
      iconActive: new URL(
        "../../../components/assets/apiDocumentationActive.svg",
        import.meta.url
      ),
    },
  ];
});

const openMobile = async () => {
  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf("Apple") != -1) &&
    !window.MSStream;
  const isAndroid =
    /Android/.test(navigator.userAgent) || navigator.userAgent.indexOf("Win");
  const appStoreUrl = "https://apps.apple.com/us/app/eazipay-business/id6446281426";

  // Define the URLs for the App Store and Play Store
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.myeazipay.business";

  // Open the respective store based on the user's platform
  if (isIOS) {
    window.location.href = appStoreUrl;
  } else if (isAndroid) {
    window.location.href = playStoreUrl;
  } else {
    // Handle other platforms if needed
    console.warn("Unsupported platform");
  }
};
</script>

<style lang="scss" scoped>
.over {
  box-shadow: 0px 20px 48px 0px #aaaaaa4a;
}

.hamburger {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 2px;
  }
}

.route-menu {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #000000;
    border-radius: 2px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-link {
  font-weight: 500;
  transition: 0.2s ease;
  cursor: pointer;
}

.auth-links {
  @apply flex items-center gap-2;

  a {
    @apply w-[108px] rounded-3xl h-10 flex items-center justify-center font-medium;
    transition: 0.24s ease;
  }
}

.link {

  // a {
  &:hover {
    background: #11453b !important;
    color: white !important;
  }

  // }
}

.login-btn {
  // border: 1px solid #11453b;
  background: rgba(17, 69, 59, 0.1);
  color: #11453b;
  font-weight: 500;
}

.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  width: 95vw;
  transform: translateX(100%);
  transition: 0.24s ease;
  visibility: hidden;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  a {
    color: #515251;
    font-size: 16px;
    font-weight: bold;
  }

  a.btn-shadow {
    color: white;
  }
}

.mobile-nav-overlay {
  background: rgb(17, 69, 59, 0.5);
  opacity: 0;
  visibility: hidden;
  opacity: 0.25s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
