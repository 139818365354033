<template>
  <div
    style="padding: 5px 12px 12px 12px; border-radius: 8px"
    class="relative border border-newAsh"
  >
    <div class="items-center cursor-pointer flex gap-2 md:gap-4 relative">
      <span
        @click="showDropdown = !showDropdown"
        class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full relative"
      >
        <img
          v-if="
            companyData &&
            companyData.companyAdmin &&
            companyData.companyAdmin.pfp
          "
          alt="..."
          class="w-full h-full object-cover rounded-full align-middle border-none shadow-lg"
          :src="
            companyData &&
            companyData.companyAdmin &&
            companyData.companyAdmin.pfp
          "
        />
        <div
          v-else
          class="p-2 h-10 w-10 sm:w-11 sm:h-11 flex items-center justify-center bg-primary border-highlight text-lg text-white font-medium rounded-full"
        >
          {{ initials(getAdminName).str }}
        </div>

        <div
          class="absolute md:hidden bg-mint w-4 h-4 rounded-full flex justify-center items-center right-0 bottom-0"
        >
          <img
            class="w-3 h-3 self-center"
            :src="getPackageIcon(current.description)"
            alt=""
          />
        </div>
      </span>

      <div class="hidden md:flex flex-col text-left mt-2 gap-1">
        <span
          @click="showDropdown = !showDropdown"
          class="font-bold text-dark-900 capitalize"
          >{{
            getAdminName && getAdminName != undefined ? getAdminName : "Admin"
          }}</span
        >

        <div class="flex items-center gap-2">
          <span
            style="color: #898989"
            @click="showDropdown = !showDropdown"
            class="text-sm capitalize font-bold"
            >{{ roleName ? roleName : "Admin" }}</span
          >
          <TierIndicator />
          <!-- <div
            v-show="current && current.description"
            @click="$router.push({ name: 'Pricing' })"
            class="cursor-pointer bg-mint flex gap-1 md:gap-1 justify-self-start p-1 rounded-md items-center"
          >
            <div class="flex flex-col items-center justify-center rounded-xl">
              <img
                class="w-4 h-4 self-center"
                :src="getPackageIcon(current.description)"
                alt=""
              />
            </div>
            <span class="text-bodyDark text-xs font-bold">{{
              current.description
            }}</span>
          </div> -->
        </div>
      </div>
      <div style="height: 48px" class="mt-2 border border-newAsh"></div>
      <div
        @click="showDropdown = !showDropdown"
        style="width: 24px; height: 24px; border-radius: 4px"
        class="bg-newGrey3 p-1 flex justify-center items-center self-center ml-3"
      >
        <i
          style="color: #898989"
          :class="showDropdown ? 'fas fa-angle-right' : 'fas fa-angle-down'"
        ></i>
      </div>
    </div>
    <div
      v-show="showDropdown"
      class="flex z-10 flex-col w-60 md:w-64 rounded-2xl bg-white absolute md:mt-4 mt-5 -right-4 top-19 py-2 shadow-lg text-sm"
    >
      <div
        v-for="(item, i) in options"
        :key="i"
        @click="optionsFN(item)"
        class="flex items-center gap-2 md:gap-4 px-5 py-3 cursor-pointer relative"
        v-show="item.show"
      >
        <span class="w-4">
          <img v-if="selected === item.name" :src="item.active" alt="" />
          <img v-else :src="item.icon" alt="" />
        </span>

        <div class="flex items-center gap-1 md:gap-2">
          <span
            class="text-sm"
            :class="selected === item.name ? 'text-secondary' : ''"
            >{{ item.name }}</span
          >

          <div
            v-if="current && current.description && item.link === 'Pricing'"
            class="bg-mint flex gap-1 md:gap-1 justify-self-start p-1 rounded-md items-center"
          >
            <div class="flex flex-col items-center justify-center rounded-xl">
              <img
                class="w-3 h-3 self-center"
                :src="getPackageIcon(current.description)"
                alt=""
              />
            </div>
            <span class="text-bodyDark whitespace-nowrap text-xs font-medium">{{
              current.description
            }}</span>
          </div>
        </div>

        <div
          v-show="item.others && selected === item.name"
          class="flex z-10 flex-col bg-white absolute -left-44 -ml-1 top-0 py-2 shadow-lg text-sm"
        >
          <div
            v-for="(item, i) in item.others"
            :key="i"
            @click="optionsFN(item)"
            class="flex items-center hover:text-secondary gap-4 px-5 py-3 cursor-pointer relative"
          >
            <span class="w-4">
              <img v-if="selected === item.name" :src="item.active" alt="" />
              <img v-else :src="item.icon" alt="" />
            </span>
            <span :class="selected === item.name ? 'text-secondary' : ''">{{
              item.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <add-account
    v-if="showAddAccount"
    @close="(showAddAccount = false), (selected = null)"
    :formType="addAccountType"
  />
  <div
    @click="(showDropdown = false), (selected = '')"
    :class="!showDropdown ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>
</template>

<script setup>
import { logout } from "@/api/api";
import { computed, onMounted, ref } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

import TierIndicator from "@/components/Dashboard/TierIndicator.vue";

import AddAccount from "@/components/Settings/Edit/AddAccount.vue";

import profile from "@/assets/icons/user-view.svg?url";
import activeProfile from "@/assets/icons/active-user-view.svg?url";

import accountAdd from "@/assets/icons/user-add.svg?url";
import activeAccountAdd from "@/assets/icons/active-user-add.svg?url";

import plus from "@/assets/icons/plus-dark.svg?url";
import activePlus from "@/assets/icons/active-plus.svg?url";

import settings from "@/assets/icons/settings.svg?url";
import activeSettings from "@/assets/icons/active-settings.svg?url";

import logoutIcon from "@/assets/icons/logout.svg?url";
import activeLogout from "@/assets/icons/active-logout.svg?url";

import subsidiary from "@/assets/icons/subsidiary.svg?url";
import activeSubsidiary from "@/assets/icons/subsidiaryActive.svg?url";

import switchIcon from "@/assets/icons/switch2.svg?url";
import activeSwitch from "@/assets/icons/switchActive.svg?url";

import coinStack from "@/assets/icons/coin-stack.svg?url";
import coinStackActive from "@/assets/icons/coin-stack-active.svg?url";

import image from "@/assets/img/avatar-5.png";
import starter from "@/assets/icons/easi-starter.svg?url";
// import plus from "@/assets/icons/easi-plus.svg?url";
import premium from "@/assets/icons/easi-premium.svg?url";
import business from "@/assets/icons/easi-business.svg?url";

const store = useDataStore();

const { companyView, checkLoading } = storeToRefs(store);
const { query } = store;
const router = useRouter();
const { getAvatar } = helperFunctions;

const companyDetails = computed(() => store.getCompanyDetails);

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
}
const roleName = ref("");
const current = ref({});

async function getCompanySubscription() {
  try {
    const res3 = await query({
      endpoint: "GetCompanySubscriptionV3",
      service: "PAYROLL",
    });
    current.value = res3.data.baseSub;
  } catch (e) {
    console.log(e);
  }
}
onMounted(async () => {
  await queryCompany();
  await getCompanySubscription();
  const empRole = window.localStorage.getItem("role");
  if (empRole) {
    roleName.value = empRole;
  }
});

const selected = ref("");
const addAccountType = ref("");
const showDropdown = ref(false);
const showAddAccount = ref(false);

const options = [
  {
    name: "Profile",
    link: "Profile",
    show: true,
    path: "/settings/profile",
    icon: profile,
    active: activeProfile,
  },
  {
    name: "Pricing plan",
    link: "Pricing",
    show: true,
    path: "/pricing",
    icon: coinStack,
    active: coinStackActive,
  },
  {
    name: "Create New Account",
    link: "Register",
    show: true,
    path: "/register",
    icon: accountAdd,
    active: activeAccountAdd,
  },
  // {
  //   name: "Add Subsidiaries",
  //   link: null,
  //   path: null,
  //   icon: subsidiary,
  //   active: activeSubsidiary,
  // },

  {
    name: "Add more accounts",
    link: null,
    path: null,
    icon: subsidiary,
    show:
      companyDetails.value &&
      companyDetails.value.registrationType === "COMPANY"
        ? true
        : false,
    active: activeSubsidiary,
    others: [
      {
        name: "Business Account",
        link: null,
        path: null,
        icon: profile,
        active: activeProfile,
      },
      {
        name: "Personal Account",
        link: null,
        path: null,
        icon: profile,
        active: activeProfile,
      },
    ],
  },
  {
    name: "Switch Account",
    link: "ManageAccount",
    path: "/settings/manage-account",
    show: true,
    icon: switchIcon,
    active: activeSwitch,
  },

  // {
  //   name: "Upgrade Account",
  //   link: null,
  //   path: null,
  //   icon: plus,
  //   active: activePlus,
  // },
  {
    name: "Settings",
    link: "Profile",
    path: "/settings",
    icon: settings,
    show: true,
    active: activeSettings,
  },

  {
    name: "Logout",
    link: null,
    show: true,
    path: "/login",
    icon: logoutIcon,
    active: activeLogout,
  },
];

const companyData = computed(() => store.getCompanyAdmin);

// console.log("COMPANY DATA", companyDetails.value);
function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    if (name && name.length) {
      let color;
      // Get color

      const arr = name.split(" ");
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(arr[0]).charAt(0);
      const initialTwo = String(arr[1]).charAt(0);
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return { str, color };
    } else {
      return { str: "AA", color: "#189F00" };
    }
  };
});

const getAdminName = computed(() => {
  let name = "";

  // log(companyData.value);

  if (
    companyData.value &&
    companyData.value.companyAdmin &&
    companyData.value.companyAdmin.firstName &&
    companyData.value.companyAdmin.lastName
  ) {
    name = `${companyData.value.companyAdmin.firstName} ${companyData.value.companyAdmin.lastName}`;
  }
  return name;
});
const getPackageIcon = (name) => {
  const icons = {
    EaziStarter: starter,
    EaziPlus: plus,
    'EaziPlus Annual': plus,
    EaziPremium: premium,
    'EaziPremium Annual': premium,
    EaziBusiness: business,
  };

  return name ? icons[name] : null;
};

const optionsFN = (arg) => {
  selected.value = arg.name;
  if (arg.name === "Business Account") {
    addAccountType.value = "business";
    showAddAccount.value = true;
    showDropdown.value = false;
  } else if (arg.name === "Personal Account") {
    addAccountType.value = "personal";
    showAddAccount.value = true;
    showDropdown.value = false;
  } else if (arg.link) {
    router.push({ name: arg.link });
    showDropdown.value = false;
  } else if (arg.name === "Logout") {
    logout("logout");
  }
};

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>
