<template>
  <div>
    <router-link v-if="isLink" :to="to" :class="btnClass">
      <slot></slot>
      <PageIcon v-show="withIcon" icon="arr" />
    </router-link>
    <button v-else :type="type" :class="btnClass" :disabled="isDisabled">
      <slot></slot>
      <PageIcon v-show="withIcon" icon="arr" />
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import PageIcon from "./PageIcon.vue";

const props = defineProps({
  withIcon: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "pry",
    validator: (value) => ["pry", "main", "tertiary", "sec"].includes(value),
  },
  to: {
    type: String,
  },
  isLink: {
    type: Boolean,
  },
  type: {
    type: String,
    default: "button",
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

// const btnBgColor = computed(() => ({
//   '--btn-bg': props.bgColor
// }))

const btnClass = computed(() => {
  const variant = props.variant ? `btn-${props.variant}` : "";

  return `${variant}`;
});
</script>

<style lang="scss" scoped>
button,
a {
  @apply w-full h-full rounded-[56px] flex justify-center items-center gap-x-2;
  background: var(--btn-bg);
  transition: 0.24s ease;
  outline: none;
  border: none;
}

button:focus {
  outline: none !important;
  border: none !important;
}

// button:disabled {
//   cursor: not-allowed;
//   opacity: 0.5;
//   background-color: inherit;
// }

.btn-pry {
  @apply bg-[#E7ECEB];
  color: rgba(17, 69, 59, 1);

  &:hover {
    @apply hover:bg-[#D9EBCD] font-semibold;
    box-shadow: 0px 20px 48px 0px rgba(217, 235, 205, 1); //rgba(255, 255, 255, 0.2);
  }
}

.btn-tertiary {
  // @apply bg-[];
  background: rgba(17, 69, 59, 0.1);
  color: rgba(17, 69, 59, 1);
}

.btn-main {
  @apply bg-[#11453b] text-white;
  box-shadow: 0px 20px 56px 24px rgba(17, 69, 59, 0.14);
}

.btn-sec {
  @apply bg-white;
  color: rgba(17, 69, 59, 1);
}
</style>
