<template>
  <section class="section">
    <h6 class="text-14 font-medium uppercase text-[#888888]">
      ️️<span class="text-xs text-[#474747]">⚫</span> our solutions
    </h6>
    <div class="section-container">
      <section class="left-side">
        <h3>
          Explore Our <br />
          Standout Solutions
        </h3>
        <div class="mt-6 w-full flex flex-col">
          <nav class="accordion">
            <button @click="tabControl(tab.id)" v-for="tab in tabs" :key="tab.id"
              :class="{ active: activeTab === tab.id }">
              {{ tab.label }}
            </button>
          </nav>

          <ul class="mt-6 h-auto">
            <li v-for="(item, index) in activeContent.solutions" :key="index" class="flex items-center gap-2 py-3">
              <img src="./assets/circle-check.svg?url" alt="circle check" class="size-5" />
              <p>{{ item }}</p>
            </li>
          </ul>
        </div>
      </section>
      <section class="stacked-container">
        <div class="w-full h-full">
          <ul class="stacked">
            <li v-for="(image, index) in stackedImages" :key="index">
              <img :src="image" alt="tab image" class="" :class="{
                'move-to-front': animateTabImage,
              }" />
            </li>
          </ul>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";

const activeTab = ref("payroll");
const animateTabImage = ref(false);
const prevTab = ref(null);

const tabs = ref([
  {
    id: "payroll",
    label: "PAYROLL",
  },
  {
    id: "paye",
    label: "PAYE",
  },
  {
    id: "pension",
    label: "PENSION",
  },
]);

const solutionItems = ref({
  payroll: {
    solutions: [
      "️‌‌Instant Salary Payment to employees ",
      "Prompt Monthly Settlement of Statutory Deductions by the 10th day",
      "Employee Loans + Salary Advance",
    ],
    image: new URL("./assets/payroll.png", import.meta.url),
    isAnimating: false,
  },
  paye: {
    solutions: [
      "‌Instant and Accurate PAYE Tax Calculations ",
      "‌Monthly PAYE Payment with State Government receipts from 36 states + FCT",
      "‌Annual Tax Filing Services for All Customers",
    ],
    image: new URL("./assets/paye.png", import.meta.url),
    isAnimating: false,
  },
  pension: {
    solutions: [
      "‌Monthly Pension Payment with Full Payment receipts ",
      "‌Hassle-Free Pension Registration (Employer & Employee)",
      "‌PENCOM Certificate Application",
    ],
    image: new URL("./assets/pension.png", import.meta.url),
    isAnimating: false,
  },
});

const activeContent = computed(() => solutionItems.value[activeTab.value]);

const stackedImages = computed(() => {
  const allImages = Object.values(solutionItems.value).map((item) => item.image);
  const activeImage = activeContent.value.image;

  return [activeImage, ...allImages.filter((img) => img !== activeImage)];
});

const tabControl = async (id) => {
  if (activeTab.value === id) return;
  animateTabImage.value = false;
  await nextTick();

  activeTab.value = id; // Change active tab

  // Delay to reapply animation
  setTimeout(() => {
    animateTabImage.value = true;
  }, 50);
};
</script>

<style lang="scss" scoped>
$default-diff: 12px;
$default-width: 100%;
$default-min: 10%;

.section {
  @apply w-full h-full xl:h-[662px] mt-16 md:mt-0 px-4 xl:px-0 xl:w-[1100px] mx-auto py-20;
}

.section-container {
  @apply w-full flex justify-between items-center h-full lg:h-[435px] mt-8 gap-8 flex-wrap-reverse md:flex-nowrap relative;

  .left-side {
    @apply w-full md:w-[410px] h-full;

    h3 {
      @apply font-medium text-[28px] md:text-4xl leading-9 md:leading-[48px] tracking-tight text-[#191919];
    }

    .accordion {
      @apply w-full h-14 rounded-4xl bg-[#eaeaea] flex items-center justify-between p-2 transition-all;
      border: 1px solid rgba(234, 234, 234, 1);

      button {
        @apply border-none outline-none uppercase w-[104px] h-10 flex items-center justify-center rounded-4xl text-14 font-medium text-[#474747];
        transition: background 0.3s ease-in-out;

        &.active {
          @apply bg-white text-primary-hover;
        }
      }
    }
  }
}

.stacked-container {
  @apply flex-1 flex items-end justify-end h-full relative;
}

.stacked {
  @apply w-full relative h-[380px] sm:h-[450px] lg:h-full;

  li {
    @apply w-[500px] absolute h-[260px] sm:h-[370px] bg-cover right-0 left-0;
    transition: all 100ms ease-in-out;
    margin: 0 auto;
    padding-top: 20px;

    &:nth-child(1) {
      top: $default-diff * 5;
      width: 100%;
      z-index: 15;

      @media (min-width: 640px) {
        width: $default-width - ($default-min * 1);
      }
    }

    &:nth-child(2) {
      top: $default-diff * 4;
      // width: $default-width - ($default-min * 3);
      width: 80%;
      z-index: 10;

      @media (min-width: 640px) {
        width: $default-width - ($default-min * 3);
      }
    }

    &:nth-child(3) {
      top: $default-diff * 3;
      width: 60%;
      z-index: 7;

      @media (min-width: 640px) {
        width: $default-width - ($default-min * 5);
      }
    }

    img {
      @apply object-fill w-full h-full shadow-2xl rounded-4xl;
      -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.2);
      // box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
      transition: all 100ms ease-in-out;
      box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.move-to-front {
  animation: bringToFront 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  // animation: bringToFront 0.5s ease-in-out;
  // z-index: 3;
}

@keyframes bringToFront {
  0% {
    transform: scale(0.9) translateY(30px);
    // opacity: 0.5;
  }

  50% {
    transform: scale(1.1) translateY(-10px);
    // opacity: 0.8;
  }

  70% {
    transform: scale(0.98) translateY(0px);
    // opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0px);
    // opacity: 1;
  }
}

/* Move the previous images back */
.move-back {
  animation: moveBack 0.5s ease-in-out;
}

@keyframes moveBack {
  0% {
    transform: scale(1.1) translateY(-10px);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 0.6;
  }
}
</style>
