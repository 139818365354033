<template>
  <Teleport to="body">
    <Transition name="modal">
      <section class="modal">
        <div class="modal-content">
          <div class="modal-top">
            <div class="w-[402px] text-black">
              <h4 class="text-xl md:text-2xl font-bold">Let’s Get Started!</h4>
              <p class="md:text-[18px] font-medium pt-2 leading-6">
                By Answering these questions, we will let you know whether you are
                overpaying your taxes.
              </p>
            </div>
            <img src="./assets/survey-img.png" alt="" class="w-[154px] absolute bottom-0 right-20 hidden md:block" />
            <span @click="handleClose" role="button"
              class="size-7 bg-white absolute top-6 right-7 flex items-center justify-center rounded-lg">
              <i class="pi pi-times" />
            </span>
          </div>
          <i class="hrozontal-line" />
          <form @submit.prevent="handleSubmit" class="survey-content">
            <h3>{{ currentQuestion.text }}</h3>

            <div class="mt-3 w-full flex flex-wrap gap-2 mb-3">
              <label v-for="option in currentQuestion.options" :for="option" :key="option">
                <input v-if="currentStep !== 3" type="radio" :value="option" v-model="currentQuestion.selectedOption"
                  @change="handleSelectOption(option)" :id="option" class="sr-only" />

                <input v-else type="checkbox" :value="option" v-model="currentQuestion.selectedOption"
                  @change="toggleOption(option)" :id="option" class="sr-only" />
                <p class="pills-btn" :class="currentStep === 3 ? 'rounded-lg bg-[#f3f3f3]' : 'rounded-3xl'">
                  {{ option }}
                  <span v-if="
                    currentStep === 3 && currentQuestion.selectedOption.includes(option)
                  " class="pi pi-check text-[#474747]" />
                </p>
              </label>
            </div>
            <div v-if="currentQuestion.showTextarea" class="w-full mb-6">
              <textarea class="text-area" v-model="currentQuestion.otherTxt" placeholder="Please specify" />
            </div>

            <i class="hrozontal-line" />

            <div class="w-full mt-6 md:w-[560px] mx-auto flex gap-4">
              <EaziBtn type="button" v-if="currentStep > 0" @click="prevStep" class="w-[85px] h-10">
                Back
              </EaziBtn>
              <button v-if="currentStep === 3" :disabled="!currentQuestion.selectedOptions ||
                currentQuestion.selectedOptions.length === 0
                " type="button" @click="nextStep" class="btn" :class="!currentQuestion.selectedOptions ||
                    currentQuestion.selectedOptions.length === 0
                    ? 'bg-primary/35 cursor-not-allowed'
                    : 'bg-primary cursor-pointer'
                  ">
                Next
              </button>

              <button :disabled="!currentQuestion.selectedOption" type="submit" class="btn"
                v-if="currentStep === surveyQuestions.length - 1" :class="!currentQuestion.selectedOption
                    ? 'bg-primary/35 cursor-not-allowed'
                    : 'bg-primary cursor-pointer'
                  ">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Transition>
  </Teleport>
  <div v-if="showDoneModal" class="second-modal">
    <div class="content">
      <div class="w-full h-[120px] md:h-[152px] pt-4 flex items-center justify-center">
        <img src="./assets/check-mark.gif" alt="check mark" class="w-[100px] block md:w-auto" />
      </div>
      <div class="w-full p-4 flex flex-col items-center gap-4">
        <h3 class="font-bold text-2xl">Form Submitted!</h3>
        <p class="text-[#888888]">Our representative will reach out to you!</p>
        <EaziBtn @click="handleCloseDoneModal" variant="main" class="w-full h-11">Done
        </EaziBtn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import EaziBtn from "./ui/EaziBtn.vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data";

const store = useDataStore();

const toast = useToast();
const { mutate } = store;

const emit = defineEmits(["close"]);

const handleClose = () => {
  emit("close");
};

const showDoneModal = ref(false);

const handleCloseDoneModal = () => {
  showDoneModal.value = !showDoneModal.value;
  handleClose();
};

const surveyQuestions = ref([
  {
    id: "1",
    text: "What is the nature of your business?",
    options: [
      "Industry One",
      "Industry Two",
      "Industry Three",
      "Industry Four",
      "Industry Five",
      "Industry Six",
      "Industry Seven",
      "Industry Eight",
    ],
    selectedOption: "",
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "2",
    text: "Is Your Business Registered?",
    options: ["No", "Yes"],
    selectedOption: null,
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "3",
    text: "What Year Did Your Business Commence Operation?",
    options: [
      "Less than 1 Year",
      "1 - 2 years",
      "3 - 5 years",
      "6 - 10 years",
      "11 - 15 years",
      "Above 15 years",
    ],
    selectedOption: null,
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "4",
    text: "What do you find difficult to do during your monthly payroll? ",
    options: [
      "Accuracy in Calculations",
      "Timely Deduction & Remittance",
      "Generating Compliance Reports",
      "Others",
    ],
    selectedOption: [],
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "5",
    text:
      "Eazipay sends your official government receipt for PAYE payments. Do you want to try it?",
    options: ["No", "Yes"],
    selectedOption: null,
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "6",
    text: "What is Your Business Structure?",
    options: [
      "Sole Proprietorship",
      "Partnership",
      "Limited Liability Company",
      "Non-Governmental Organisation",
      "Others",
    ],
    selectedOption: null,
    showTextArea: false,
    otherTxt: "",
  },
  {
    id: "7",
    text: "How Many Employees Do You Have?",
    options: ["1 -5", "6 - 20", "21 - 50", "51 - 100", "Above 100"],
    selectedOption: null,
    showTextArea: false,
    otherTxt: "",
  },
]);

const currentStep = ref(0);
const currentQuestion = computed(() => surveyQuestions.value[currentStep.value]);

const nextStep = () => {
  if (currentStep.value < surveyQuestions.value.length - 1) {
    currentStep.value++;
  }
};

// Move to previous step
const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};

const toggleOption = (option) => {
  // Ensure selectedOptions is always an array
  if (!currentQuestion.value.selectedOptions) {
    currentQuestion.value.selectedOptions = [];
  }

  const selectedOptions = currentQuestion.value.selectedOptions;

  if (selectedOptions.includes(option)) {
    // Remove the option if already selected
    currentQuestion.value.selectedOptions = selectedOptions.filter((o) => o !== option);
  } else {
    // Add the option if not selected
    currentQuestion.value.selectedOptions.push(option);
  }

  // Show textarea only if "Others" is selected
  currentQuestion.value.showTextarea = currentQuestion.value.selectedOptions.includes(
    "Others"
  );
};

const handleSelectOption = (option) => {
  currentQuestion.value.selectedOption = option;
  currentQuestion.value.showTextarea = option === "Others";

  if (currentStep.value === 1) {
    if (option === "No") {
      handleClose();
    } else if (option === "Yes") {
      setTimeout(() => nextStep(), 500);
    }
    return;
  }
  if (currentStep.value === 5) {
    currentQuestion.value.showTextarea = false;
  } else {
    // For other steps, toggle the textarea when 'Others' is selected
    currentQuestion.value.showTextarea = option === "Others";
  }
  if (currentStep.value !== 3) {
    setTimeout(() => nextStep(), 500);
  }
};

// const transformToBoolean = (value) => {
//   if (value === "Yes") return true;
//   if (value === "No") return false;
//   return value;
// };

const isRegistered = computed(() => surveyQuestions.value[1].selectedOption === "Yes");
const isInterestedInReceipt = computed(
  () => surveyQuestions.value[4].selectedOption === "Yes"
);

const handleSubmit = async () => {
  const payload = {
    businessNature: surveyQuestions.value[0].selectedOption,
    businessStructure: surveyQuestions.value[5].selectedOption,
    isRegistered: isRegistered.value,
    commencementYear: surveyQuestions.value[2].selectedOption,
    employeeCount: surveyQuestions.value[6].selectedOption,
    interestedInEazipayReceipt: isInterestedInReceipt.value,
    payrollDifficulties: {
      options: surveyQuestions.value[3].selectedOption,
      others: surveyQuestions.value[3].otherTxt,
    },
  };

  try {
    let res = await mutate({
      endpoint: "SaveSurvey",
      data: { input: payload },
      service: "PAYROLL",
    });
    if (res.success) {
      showDoneModal.value = !showDoneModal.value;
    } else {
      toast.error("something went wrong!");
    }
  } catch (error) {
    toast.error(error.message);
  }
};
</script>

<style lang="scss" scoped>
.modal {
  @apply fixed inset-0 grid place-items-center p-4 w-full h-full;
  background: rgba(17, 69, 59, 0.7);
  z-index: 10;
}

.second-modal {
  @apply fixed inset-0 grid place-items-center p-4 w-full h-full;
  background: rgba(17, 69, 59, 0.7);
  z-index: 30;

  .content {
    @apply w-[310px] md:w-[343px] h-[328px] rounded-[40px] bg-white;
  }
}

.modal-content {
  @apply bg-white h-[670px] md:h-[560px] w-[343px] md:w-[760px] rounded-[40px];

  .modal-top {
    @apply bg-[url('./assets/survey-mobile.png')] pt-10 md:pt-0 md:bg-[url('./assets/survey-bg.png')] h-[168px] md:h-[152px] w-full bg-cover bg-no-repeat rounded-t-[40px] flex justify-between md:pl-[100px] relative px-4 md:px-0 items-center overflow-y-hidden;
  }
}

.hrozontal-line {
  @apply w-full md:w-[552px] h-px mx-auto block mt-4;
  background: rgba(243, 243, 243, 1);
}

.survey-content {
  @apply w-full md:w-[552px] mx-auto mt-6 px-4 md:px-0;
}

.pills-btn {
  @apply min-w-16 py-2 px-4 text-14 flex items-center justify-center cursor-pointer gap-3;
  border: 1px solid rgba(233, 233, 233, 1);
}

input[type="radio"]:checked~p {
  border: 1px solid rgba(217, 235, 205, 1);
  background: rgba(217, 235, 205, 1);
  box-shadow: 0px 6px 12px 0px rgba(170, 170, 170, 0.11);
}

input[type="checkbox"]:checked~p {
  border: 1px solid rgba(217, 235, 205, 1);
  background: rgba(217, 235, 205, 1);
  box-shadow: 0px 6px 12px 0px rgba(170, 170, 170, 0.11);
}

.text-area {
  @apply w-full outline-none border-none h-[88px] rounded-xl px-5 py-2;
  resize: none;
  background: rgba(248, 248, 248, 1);
}

button {
  outline: none !important;
  border: none !important;
  // all: unset;
}

.btn {
  @apply w-[85px] h-10 rounded-3xl text-white;
  // background: rgba(17, 69, 59, 1);
}

.modal-enter-active,
.modal-leave-active {
  transition: 0.25s ease;
}

.modal-enter-from {
  transform: scale(1.04);
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}
</style>
