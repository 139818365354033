<template>
  <section :id="linkId" class="calc-payroll relative">
    <div class="w-full sm:w-[560px] mx-auto">
      <div class="w-full text-center">
        <h3 class="font-medium text-[28px] lg:text-4xl text-[#191919]">
          Don’t Get Ripped Off!
        </h3>
        <p class="text-xl text-[#474747] pt-6">
          Verify your payroll figures with <br class="block lg:hidden" />
          our easy calculator
        </p>
      </div>
      <img src="./assets/wiggle.svg?url" alt=""
        class="absolute -right-[4.5rem] top-1/2 -translate-y-1/2 hidden xl:block" />
      <img src="./assets/wiggle-left.svg?url" alt=""
        class="absolute -left-[5rem] top-1/2 -translate-y-1/2 hidden xl:block" />
      <form @submit.prevent="handleSubmit" class="w-full">
        <section class="tab-control">
          <label for="percent" class="tab-btn">
            <input type="radio" value="percentage" id="percent" v-model="form.allowanceType" class="sr-only" />
            <p>By Percentage</p>
          </label>
          <label for="amt" class="tab-btn">
            <input type="radio" value="currency" id="amt" v-model="form.allowanceType" class="sr-only" />
            <p>By Amount</p>
          </label>
        </section>

        <div class="calc-bg">
          <div class="w-full">
            <div class="w-full">
              <CustomInput type="currency" input-id="tsalary" :type="form.allowanceType" label="Total Taxable Salary"
                placeholder="0.00" v-model="form.totalTaxableSalary" required />
            </div>
            <h4 class="my-4 font-semibold">Allowances</h4>
            <div class="w-full grid grid-cols-2 gap-x-4">
              <div>
                <CustomInput input-id="basic" :type="form.allowanceType" label="Basic" placeholder="0%"
                  v-model="form.allowances.basic" />
              </div>
              <div>
                <CustomInput input-id="house" :type="form.allowanceType" label="Housing" placeholder="0%"
                  v-model="form.allowances.housing" />
              </div>
              <div>
                <CustomInput input-id="trns" :type="form.allowanceType" label="Transport" placeholder="0%"
                  v-model="form.allowances.transport" />
              </div>
              <div>
                <CustomInput input-id="others" :type="form.allowanceType" label="Others" placeholder="0%"
                  v-model="form.allowances.others" />
              </div>
            </div>
            <h4 class="mt-6 font-semibold">Deductions</h4>
            <div class="w-full grid grid-cols-2 gap-x-4">
              <div>
                <CustomInput type="currency" input-id="hmo" :type="form.allowanceType" label="HMO" placeholder="0.00"
                  v-model="form.deductions.hmo" />
              </div>
              <div>
                <CustomInput type="currency" input-id="dedoth" :type="form.allowanceType" label="Others"
                  placeholder="0.00" v-model="form.deductions.others" />
              </div>
              <div class="col-span-2 grid grid-cols-2 gap-4 sm:grid-cols-4 justify-between mt-2">
                <div class="check-container">
                  <label for="pay" class="text-14">PAYE</label>
                  <input type="checkbox" value="PAYE" v-model="form.benefits" name="" id="pay" />
                </div>
                <div class="check-container">
                  <label for="NSITF" class="text-14">NSITF</label>
                  <input type="checkbox" value="NSITF" v-model="form.benefits" name="" id="NSITF" />
                </div>
                <div class="check-container">
                  <label for="NHF" class="text-14">NHF</label>
                  <input type="checkbox" value="NHF" v-model="form.benefits" name="" id="NHF" />
                </div>
                <div class="check-container">
                  <label for="Pension" class="text-14">Pension</label>
                  <input type="checkbox" value="Pension" v-model="form.benefits" name="" id="Pension" />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 form-error text-14 text-center" v-if="error">
            {{ error }}
          </div>
        </div>

        <EaziBtn type="submit" variant="main" class="h-10 w-[127px] mt-6 mx-auto">
          Run Payroll
        </EaziBtn>
      </form>
      <!-- <PayrollCalculator /> -->
    </div>
  </section>

  <SiteModal v-model="showPopup" :disable-close="submitLoading" @popupclose="$emit('popup', false)" style="z-index: 999"
    class="popup max-w-lg w-full z-50" :class="{ 'result-sent': resultSent === true }">
    <div class="px-8 py-4 popup-body">
      <form v-if="!resultSent" @submit.prevent="sendResult">
        <div class="text-center mb-6">
          <h3 class="text-2xl mb-2 font-bold">Payroll Calculator</h3>
          <p>Kindly enter your email to get your result</p>
        </div>
        <SiteTextInput type="text" label="Name" v-model="name" required />
        <SiteTextInput type="email" label="Email" v-model="email" required />

        <div class="mt-4">
          <SiteButton class="w-full" type="submit" :is-loading="submitLoading">
            Send Result</SiteButton>
        </div>
      </form>

      <div v-else class="success-block text-center w-4/5 mx-auto">
        <h3 class="text-2xl mb-4 font-bold">Result Sent!</h3>
        <p>Kindly check your email for your payroll breakdown</p>
      </div>
    </div>
  </SiteModal>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import CustomInput from "./ui/CustomInput.vue";
import EaziBtn from "./ui/EaziBtn.vue";
import SiteModal from "@/www/components/SiteModal.vue";
import { useDataStore } from "@/stores/data";
import SiteButton from "@/www/components/SiteButton.vue";
import SiteTextInput from "@/www/components/SiteTextInput.vue";

defineProps({
  linkId: String,
});

const emit = defineEmits(["close", "popup"]);
const showPopup = ref(false);
const resultSent = ref(false);
const email = ref("");
const name = ref("");
const submitLoading = ref(false);

const resetPopupState = () => {
  resultSent.value = false;
  email.value = "";
  name.value = "";
};

watch(showPopup, () => {
  if (!showPopup.value) {
    resetPopupState();
  }
});

const form = reactive({
  totalTaxableSalary: "",
  allowanceType: "percentage",
  allowances: {
    basic: "",
    housing: "",
    transport: "",
    others: "",
  },
  deductions: {
    hmo: "",
    paye: "",
    nsitf: "",
    nhf: "",
    pension: "",
    others: "",
  },
  benefits: [],
});

const error = ref("");

const store = useDataStore();
const { query } = store;

const validateForm = () => {
  error.value = "";
  let allowances = Object.values(form.allowances);
  let totalAllowance = 0;

  allowances.forEach((allowance) => {
    allowance = Number(allowance);
    if (Number.isNaN(allowance) === false) {
      // allowance is a number, continue
      if (form.allowanceType === "percentage") {
        allowance = (allowance * form.totalTaxableSalary) / 100;
      }
      totalAllowance += allowance;
    }
  });

  if (form.totalTaxableSalary !== totalAllowance) {
    error.value = "Ensure total Allowance amount equal Total Gross";
    return false;
  }
  return true;
};

const prepareInput = () => {
  let allowanceDetails = [];
  Object.entries(form.allowances).forEach(([key, value]) => {
    if (value) {
      let allowance = {
        amount: Number(value),
        name: key,
      };
      allowanceDetails.push(allowance);
    }
  });
  const input = {
    usePercentageForAllowance: form.allowanceType === "percentage",
    grossAmount: Number(form.totalTaxableSalary),
    allowanceDetails: allowanceDetails,
    withholdingTax: false,
    benefits: form.benefits,
  };

  let hmo = Number(form.deductions.hmo);
  if (Number.isNaN(hmo) === false && hmo > 0) {
    form.benefits.push("HMO");
  }

  form.benefits.forEach((benefit) => {
    let benefitLowerCase = benefit.toLowerCase();
    if (benefitLowerCase === "hmo") {
      input[benefitLowerCase] = {
        amount: hmo,
      };
    } else {
      input[benefitLowerCase] = {
        amount: 0,
      };
    }
  });

  return input;
};

const handleSubmit = async () => {
  if (!validateForm()) {
    return;
  }
  showPopup.value = true;
  emit("popup", true);
};

const sendResult = async () => {
  const input = prepareInput();
  try {
    submitLoading.value = true;
    await query({
      endpoint: "CalculateForStaffLevelV3",
      payload: {
        input: input,
        firstName: name.value,
        email: email.value,
      },
      service: "PAYROLL",
    });
    resultSent.value = true;
    submitLoading.value = false;

    setTimeout(() => {
      emit("close");
    }, 2000);
  } catch { }
};
// const handleSubmit = () => {
//   console.log(form)
// }
</script>

<style lang="scss" scoped>
.calc-payroll {
  @apply w-full xl:w-[1080px] mx-auto h-full lg:h-[1180px] bg-[url('./assets/calc-bg.png')] bg-no-repeat bg-center py-[120px] px-4 lg:px-0;
  background-size: 1080px;
}

.tab-control {
  @apply w-[336px] h-14 mx-auto mt-6 rounded-4xl p-2 flex justify-between items-center;
  border: 1px solid rgba(234, 234, 234, 1);
  background-color: rgba(234, 234, 234, 1);
}

input[type="radio"]~p {
  @apply w-[160px] h-10 flex items-center justify-center rounded-4xl text-14 font-medium text-[#474747] cursor-pointer;
  transition: background 0.3s ease-in-out;
}

input[type="radio"]:checked~p {
  @apply bg-white text-[#292a29] font-semibold;
}

.calc-bg {
  @apply h-full sm:h-[670px] bg-white px-3 sm:px-14 py-5 sm:py-8 w-full sm:w-[560px] rounded-[40px] mt-6 mx-auto;
  box-shadow: 0px 20px 48px rgba(170, 170, 170, 0.29);
}

.check-container {
  @apply flex items-center justify-between w-full sm:w-[100px] h-8 rounded-lg p-2;
  border: 1px solid rgba(243, 243, 243, 1);
  background: rgba(248, 248, 248, 1);
}

input[type="checkbox"] {
  @apply size-[18px] rounded-md;
  border: 1.5px solid rgba(141, 142, 141, 1);
}

input[type="checkbox"]:checked {
  @apply bg-[#11453b] text-white border-none bg-contain bg-[url('./assets/check.png')] bg-no-repeat;
  // background-image: url('../../assets/check.png');
  background-size: 12px;
  background-position: center;
}

.form-error {
  color: #ef4444;
}
</style>
