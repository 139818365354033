<template>
  <main class="w-full h-full">
    <div class="w-full mx-auto">
      <section class="hero-banner">
        <header>
          <HeaderBox />
        </header>

        <div class="w-full xl:w-[1100px] mx-auto h-full relative mt-9 md:mt-24">
          <div class="banner-text">
            <div class="absolute left-0 sm:left-[55px] top-10 lg:top-0">
              <div class="relative w-auto">
                <img src="./assets/line.png" alt="" class="absolute left-1/2 -translate-x-1/2 bottom-3 h-[250px]" />
                <CircleSlides bg-type="first" />
              </div>
            </div>
            <div class="pill">
              <p>Our Customer Has This to Say</p>
            </div>
            <h1>
              Eazipay <br class="block sm:hidden" />
              Transformed <br class="hidden sm:block xl:hidden" />
              Our Payroll Experience
            </h1>

            <p class="text-[#F8F8F8] text-xl leading-6 lg:w-[720px] text-center">
              Payroll used to be a nightmare of errors, delays, and unhappy employees.
              Now, it’s fast, secure, and keeps our team smiling.
            </p>
            <p class="text-center text-[#E9E9E9]">-Ben, Co-Founder - Shekel, YC W23</p>
            <EaziBtn :is-link="true" to="/register" :with-icon="true" variant="pry" class="w-[213px] h-14">
              Try Eazipay Now
            </EaziBtn>
          </div>

          <div class="absolute left-0 -mt-20">
            <div class="relative w-auto">
              <img src="./assets/line.png" alt="" class="absolute left-1/2 -translate-x-1/2 bottom-2 h-[300px]" />
              <CircleSlides bg-type="second" />
            </div>
          </div>
          <div class="absolute right-0 -mt-10 top-24 xs:top-auto">
            <div class="relative w-auto">
              <img src="./assets/line.png" alt="" class="absolute left-1/2 -translate-x-1/2 bottom-4 h-[350px] -z-0" />
              <CircleSlides bg-type="third" />
            </div>
          </div>
        </div>
      </section>
      <!-- banner bottom -->
      <div class="relative">
        <div class="banner-bottom">
          <div class="flex items-center gap-2 w-full mr-9 md:mr-0">
            <img src="./assets/star-check.svg?url" alt="check mark" />
            <span class="text-14 lg:text-[16px]">Reliable Pension Remittance</span>
          </div>
          <div class="flex items-center gap-2 w-full mr-9 md:mr-0">
            <img src="./assets/star-check.svg?url" alt="check mark" />
            <span class="text-14 lg:text-[16px]">Timely PAYE & Tax Filing</span>
          </div>
          <div class="flex items-center gap-2 w-full mr-9 md:mr-0">
            <img src="./assets/star-check.svg?url" alt="check mark" />
            <span class="text-14 lg:text-[16px]">Payroll Cover & Business Support</span>
          </div>
        </div>
      </div>

      <OurSolutions />
      <section class="expertise-container">
        <img class="w-full h-24 absolute left-0 -top-5 object-fill" src="./assets/bg-shape.png" />

        <div class="w-full lg:w-[1040px] mx-auto py-[120px] text-center">
          <p class="uppercase text-[#888888]">
            <span class="text-xs">️⚫️</span> our Expertise
          </p>
          <div class="w-full md:w-[800px] mx-auto">
            <h3 class="text-[#191919] text-[28px] leading-9 sm:text-4xl font-medium py-6">
              ‌ Your Journey to Compliance Starts Here
            </h3>
            <p class="text-[#474747] text-xl">
              ‌ You don’t need to worry again about flouting any of the Finance Act in
              Nigeria. We ensure you stay compliant with the different laws of the 36
              states + FCT. 
            </p>
          </div>

          <div class="w-[316px] sm:w-[619px] mx-auto mt-12">
            <img src="./assets/journey.gif" alt="" />
          </div>

          <EaziBtn @click="handleGetStarted" class="w-[214px] mx-auto h-14 mt-12" variant="main">
            Let’s Get You Started
          </EaziBtn>
        </div>
      </section>

      <section class="social-proof">
        <h5 class="text-center font-medium text-[32px] text-[#191919]">
          Trusted by Industry Leaders
        </h5>
        <Swiper :modules="[Autoplay]" :slides-per-view="'auto'" :speed="3000" :loop="true"
          :autoplay="{ delay: 1500, disableOnInteraction: false }" class="w-[1080px] mx-auto">
          <SwiperSlide v-for="item in proofItems" :key="item.id" class="proof-items">
            <img :src="item.img" alt="" v-for="item in proofItems" :key="item.id" class="h-14 block w-auto" />
          </SwiperSlide>
        </Swiper>
      </section>
      <ClearExpectation />
      <TheBenefits />
      <CalcPayroll link-id="calculator" />
      <EaziFaqs />
    </div>
  </main>
  <div class="">
    <EaziFooter />
  </div>

  <SurveyContent v-if="showModal" @close="handleGetStarted" />
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import CircleSlides from "./ui/CircleSlides.vue";
import HeaderBox from "./ui/HeaderBox.vue";
import EaziBtn from "./ui/EaziBtn.vue";
import OurSolutions from "./OurSolutions.vue";
import EaziFaqs from "./EaziFaqs.vue";
import ClearExpectation from "./ClearExpectation.vue";
import TheBenefits from "./TheBenefits.vue";
import EaziFooter from "./EaziFooter.vue";
import CalcPayroll from "./CalcPayroll.vue";
import SurveyContent from "./SurveyContent.vue";

const showModal = ref(false);

const handleGetStarted = () => {
  showModal.value = !showModal.value;
};

const proofItems = [
  {
    id: "1",
    img: new URL("./assets/trusted/image1.png", import.meta.url),
  },
  {
    id: "2",
    img: new URL("./assets/trusted/image2.png", import.meta.url),
  },
  {
    id: "3",
    img: new URL("./assets/trusted/image3.png", import.meta.url),
  },
  {
    id: "4",
    img: new URL("./assets/trusted/image4.png", import.meta.url),
  },
  {
    id: "5",
    img: new URL("./assets/trusted/image5.png", import.meta.url),
  },
  {
    id: "6",
    img: new URL("./assets/trusted/image6.png", import.meta.url),
  },
];
</script>

<style lang="scss" scoped>
main {
  contain: paint;
  scroll-behavior: smooth;
  // overflow-x: hidden
}

.hero-banner {
  @apply w-full px-4 xl:px-0 bg-[url('./assets/banner.png')] bg-no-repeat bg-cover h-full pb-28 sm:pb-0 sm:h-[844px];

  header {
    @apply w-full xl:w-[1100px] mx-auto h-20 flex items-center relative;
  }
}

.banner-text {
  @apply w-full flex flex-col justify-between items-center gap-6;

  h1 {
    @apply w-full md:w-[690px] text-white font-bold text-4xl sm:text-5xl lg:text-[64px] leading-[48px] sm:leading-[58px] lg:leading-[77px] tracking-tighter text-center;
  }
}

.pill {
  @apply w-[224px] h-8 rounded-4xl border border-[#888888] bg-[#B9B9B933] text-[#D4D4D4] text-14 flex justify-center items-center;
}

.line {
  @apply w-[2px] border;
  background-image: linear-gradient(rgba(11, 77, 68, 1), rgba(160, 145, 94, 1));
}

.banner-bottom {
  @apply bg-no-repeat absolute top-0 lg:-top-[88px] w-full lg:w-[1100px] mx-auto rounded-none xl:rounded-t-[40px] inline-flex justify-between gap-4 h-[88px] p-6 overflow-x-auto whitespace-nowrap left-1/2 -translate-x-1/2;
  // z-index: 100;
  background-image: linear-gradient(#e5e8e5, #ffffff);
  scrollbar-width: none;
}

.expertise-container {
  @apply w-full h-full lg:h-[908px] bg-[#ecf5e8] px-4 md:px-0 relative;
  // background-color: rgba(240, 247, 235, 1);
}

.social-proof {
  @apply w-full h-[368px] py-[120px];
}

.proof-items {
  @apply w-full pt-12 mx-auto flex gap-10 flex-nowrap justify-between overflow-x-auto whitespace-nowrap;
  // animation: slide 3s linear infinite alternate;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-15%);
  }
}
</style>
